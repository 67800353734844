<template>
  <section class="ma-12">
    <v-container>
      <v-row class="d-flex justify-center">
        <app-title class="mb-5">Nos Sponsors</app-title>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="12" sm="4" class="">
          <v-img src="../../../assets/logo-ars.jpg" max-width="600"></v-img>
        </v-col>
        <v-col cols="0" sm="1"></v-col>
        <v-col cols="12" sm="4" class="">
          <v-img src="../../../assets/logo-urps.jpg" max-width="600"></v-img>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: 'LogosSection',

  components: {
    'app-title': () => import('../../../core/Title')
  }
}
</script>
